import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import News_in_img1 from "./img/news_in_img1.jpg";
import { FaEye } from 'react-icons/fa';
import Lottie from 'react-lottie';
import * as animationData from "./img/pattern.json";

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

const News_in =() =>{
    return(
        <div className="sector">
            <div className="lottie_svg">
                <Lottie options={defaultOptions} />
            </div>
            <Container className="container_width_in">
                <span className="title_in">Янгиликлар</span>
                <div className="in_news">
                    <Row>
                        <Col md="7">
                            <span className="news_img">
                                <img src={News_in_img1} />
                            </span>
                        </Col>
                        <Col md="5" className="news_right_side">
                            <div className="news_t">
                                <span className="news_date">15 Июнь 2022</span>
                                <span href="#" className="news_title">Шавкат Мирзиёев раислигида аҳолини касб-ҳунарга ўқитиш юзасидан ўтказилган йиғилишда қандай масалалар кўтарилди?</span>
                                <span className="reed_stat"><FaEye /> 2186</span>
                            </div>
                        </Col>
                    </Row>

                    <p>
                        Бугун, 15 июнь куни Президент Шавкат Мирзиёев раислигида аҳолини касб-ҳунарга ўқитиш ва мономарказлар фаолиятини такомиллаштириш
                        чора-тадбирлари юзасидан видеоселектор йиғилиши бўлиб ўтмоқда.
                    </p>
                    <p>
                        “Ҳозирги кунда тўқимачилик, кончилик, электротехника, кимё ва бошқа саноат тармоқларида, қишлоқ хўжалиги, хизматлар васервис,
                        қурилиш, тадбиркорлик соҳаларида катта-катта ўзгаришлар қилаяпмиз. Мисол учун, 2022 йилнинг ўзида 16 миллиард долларлик 9 мингдан зиёд
                        йирик ва ўрта лойиҳалар ишга туширилиб, уларга 300 мингга яқин малакали ишчилар керак бўлади.
                    </p>
                    <p>
                        Камбағалликни 2 бараварга қисқартириш бўйича марраларга ҳам одамларга фақат ва фақат замонавий касблар
                        бериш орқали эриша оламиз”, деди давлатимиз раҳбари йиғилиш аввалида.
                    </p>
                    <p>
                        Сўнгги 3 йилда “Ишга марҳамат” мономарказлари ташкил қилиниб, одамларни касбга ўргатиш бошлангани,
                        лекин бу тизим 5-10 фоиз ишсизларни қамраб олиши таъкидланди
                    </p>
                    <p>
                        Бу йил республика бўйича 555 минг нафар ёшлар меҳнат бозорига кириб келади. Уларни замонавий касбларга ўргатиш, муносиб иш билан таъминлаш
                        масалалари барча даражадаги ҳокимлар, олийгоҳ ректорлари, коллеж ва техникум директорларини ҳам ўйлантириши кераклиги кўрсатиб ўтилди.
                    </p>
                    <p>
                        Йиғилишда 330 та касб-ҳунар мактаби, 173 та коллеж ва 207 та техникуман иборат тизим самарали ишлатилмаётгани қайд этилди.
                        Улар мавжуд қувватларининг атиги 40-50 фоизидан фойдаланмоқда.
                    </p>
                    <p>
                        Энергетика, Сув хўжалиги, Уй-жой коммунал вазирликларига берилган коллежлар 20 фоиз, Қурилиш,
                        Транспорт вазирликлари коллежлари 10 фоиз қувватда ишлаяпти
                    </p>
                    <p>
                        Жорий йилда коллеж ва техникумларнинг 15 мингга яқин ўқувчилари контракт пулини тўлаш имконияти йўқлиги боис, ўқишни давом эттира олмаган.
                    </p>
                    <span className="source_title">Манба: Aхборот хизмати</span>
                </div>
            </Container>
        </div>
    )
}

export default News_in;