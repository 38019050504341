import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from 'react-lottie';
import * as animationData from "./img/pattern.json";
import ReactHighcharts from "react-highcharts";

// import "./styles.css";

function Investment_chart() {
  const config = {
    chart: {
      plotBackgroundColor: false,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Умумий",
      align: "center",
      verticalAlign: "middle",
      y: 120,
      style: {
        fontSize: 16
      }
    },
    subtitle: {
      text: "78.8",
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#f96341", "#f3a08d"]
      }
    },
    series: [
      {
        size: "80%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 78.8
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
          top: "-45",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config2 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Сирдарё вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 100,
      style: {
        fontSize: 16
      }
    },
    subtitle: {
      text: "25.4",
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#3f9bf9", "#8abdf1"]
      }
    },
    series: [
      {
        size: "60%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 25.4
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config3 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Қорақалпоғистон Республикаси",
      align: "center",
      verticalAlign: "middle",
      y: 100,
      style: {
        fontSize: 16
      }
    },
    subtitle: {
      text: "2.1",
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#3f9bf9", "#8abdf1"]
      }
    },
    series: [
      {
        size: "60%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 2.1
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

//   const defaultOptions = {
//     loop: true,
//     autoplay: true, 
//     animationData: animationData,
//     rendererSettings: {
//       preserveAspectRatio: 'xMidYMid slice'
//     }
//   };

  return (
      <div className="sector">
          {/* <div className="lottie_svg">
              <Lottie options={defaultOptions} />
          </div> */}
          <Container>
          <span class="title_in">Тўғридан-тўғри хорижий инвестицияларнинг умумий инвестиция ҳажмидаги улуши (фоиз)</span>
          <span className="mini_text">2022 йил 1 январ ҳолатига</span>
          <Row>
              <Col md="5">
                <Row className="flex_b">
                    <Col md="6">
                        <ReactHighcharts config={config} />
                    </Col>
                    <Col md="6">
                        <div>
                            <span className="chart_t">Энг юқори даража</span>
                            <ReactHighcharts config={config2} />
                        </div>
                        <div>
                            <span className="chart_t">Энг қуйи даража</span>
                            <ReactHighcharts config={config3} />
                        </div>
                    </Col>
                </Row>
              </Col>
              <Col md="7">
              <ul className="doctors_number">
                <li>
                    <div>
                        <i>1.</i> Андижон вилояти
                        <span>6.2</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>2.</i> Бухоро вилояти
                        <span>9.3</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>3.</i> Жиззах вилояти
                        <span>16.9</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>4.</i> Қашқадарё вилояти
                        <span>9.6</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>5.</i> Навоий вилояти
                        <span>14.4</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>6.</i> Наманган вилояти
                        <span>7.7</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>7.</i>Самарқанд вилояти
                        <span>9.9</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>8.</i> Сурхондарё вилояти
                        <span>19.9</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>9.</i> Сирдарё вилояти
                        <span>25.4</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>10.</i> Тошкент вилояти
                        <span>20.9</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>11.</i> Фарғона вилояти
                        <span>17.1</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>12.</i> Хоразм вилояти
                        <span>9.1</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>13.</i> Тошкент шаҳри
                        <span>10.3</span>
                    </div>
                </li>
                <li>
                    <div>
                        <i>14.</i> Қорақалпоғистон Республикаси
                        <span>2.1</span>
                    </div>
                </li>
            </ul>
              </Col>
          </Row>
            <span className="another_text">Ҳудудларда йўналишларни баҳолаш кўрсаткичлари <a href="https://rating.idm.uz">Иқтисодий тараққиёт ва камбағалликни қисқартириш вазирлиги</a> ҳамда <a href="https://b-indicator.uz" target="_blank">Адлия вазирлиги</a> томонидан НИЗОМ асосида ярим йиллик ва йил якуни бўйича амалга оширилади.</span>
           </Container>
      </div>
  );
}
export default Investment_chart;
