import React from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Form } from "react-bootstrap";
import Slider_1 from './img/slider_img1.jpg';
import Slider_2 from './img/slider_img2.jpg';
import { FiSearch } from 'react-icons/fi';
 
const handleDragStart = (e) => e.preventDefault();

const items = [
  <img src={Slider_1} onDragStart={handleDragStart} role="presentation" />,
  <img src={Slider_2} onDragStart={handleDragStart} role="presentation" />,
];


const Slider =() => {
    return(
        <div className="slider">
            <AliceCarousel mouseTracking items={items} animationType={"fadeout"} infinite="loop" autoPlay="boolen" autoPlayInterval={6000} animationDuration={3000} />
            <div className="discription">
                <span className="discription_title">e-monitoring.gov.uz</span>
                <span className="discription_text">Ўзбекистон Республикаси Президенти Администрациясининг 2022 йил 12 февралдаги АФ-8-сонли фармойишига ижроси юзасидан “Ислоҳотларнинг амалга оширилишини баҳолаш ва мониторинг қилиш тизимини шакллантириш” тўғрисидаги фармойиши ижросини таъминлаш мақсадида мазкур портал ишлаб чиқилди</span>
                <Form className="form_search">
                    <Form.Group>
                        <Form.Control type="search" placeholder="Қидирув матнини киритинг" />
                        <button type="submit" className="sub_btn"><FiSearch className="svg_b" /></button>
                    </Form.Group>
                </Form>
                <ul className="discription_info">
                    <li>
                        <span>45</span>
                        Йўналиш
                    </li>
                    <li>
                        <span>150</span>
                        Ташкилотлар<br></br> сони
                    </li>
                    <li>
                        <span>7,5</span>
                        Ўртача<br></br> балл
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Slider;