import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from 'react-lottie';
import * as animationData from "./img/pattern.json";
import Ic_1 from "./img/ic_1.png";
import Ic_2 from "./img/ic_2.png";
import Ic_3 from "./img/ic_3.png";
import Ic_4 from "./img/ic_4.png";
import Ic_5 from "./img/ic_5.png";
import Ic_6 from "./img/ic_6.png";
import Ic_7 from "./img/ic_7.png";
import Ic_8 from "./img/ic_8.png";
import { Link } from "react-router-dom";

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

const Inner_page =() =>{
    return(
        <div className="sector">
            <div className="lottie_svg">
                <Lottie options={defaultOptions} />
            </div>
            <Container className="container_width_in">
                <span className="title_in">Тадбиркорлик ва бизнес</span>
                <div className="has_pad">
                    <Row> 
                        <Col md="3" className="in_sec">
                            <Link to="/stat">
                                <span><img src={Ic_1} /><i>1</i></span>
                                <p>Тадбиркорлик фаолиятини текшириш ва тафтиш қилиш</p>
                            </Link>
                        </Col>
                        <Col md="3" className="in_sec">
                            <Link to="/territory_rating">
                                <span><img src={Ic_2} /><i>2</i></span>
                                <p>Ҳудудларда тадбиркорликнинг ривожланганлик даражаси</p>
                            </Link>
                        </Col>
                        <Col md="3" className="in_sec">
                            <a href="#">
                                <span><img src={Ic_3} /><i>3</i></span>
                                <p>Тадбиркорлардан келиб тушган мурожаатлар</p>
                            </a>
                        </Col>
                        <Col md="3" className="in_sec">
                            <a href="#">
                                <span><img src={Ic_4} /><i>4</i></span>
                                <p>Бўш турган бино-иншоотлар ва участкалардан фойдаланиш</p>
                            </a>
                        </Col>
                        <Col md="3" className="in_sec">
                            <a href="#">
                                <span><img src={Ic_5} /><i>5</i></span>
                                <p>Тадбиркорликнинг ялпи ҳудудий маҳсулотдаги улуши</p>
                            </a>
                        </Col>
                        <Col md="3" className="in_sec">
                            <a href="#">
                                <span><img src={Ic_6} /><i>6</i></span>
                                <p>Тадбиркорлар томонидан аҳолини иш билан таъминлаш</p>
                            </a>
                        </Col>
                        <Col md="3" className="in_sec">
                            <a href="#">
                                <span><img src={Ic_7} /><i>7</i></span>
                                <p>Тадбиркорларнинг давлат буджет тушумларидаги улуши</p>
                            </a>
                        </Col>
                        <Col md="3" className="in_sec">
                            <a href="#">
                                <span><img src={Ic_8} /><i>8</i></span>
                                <p>Тадбиркорлик учун кредитлар ажратилиши</p>
                            </a>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Inner_page;