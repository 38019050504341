import React from 'react';
import Portal_page from "./e-monitoring/portal";
import Sectors_page from "./e-monitoring/sectors";

function Main_page(){
    return(
        <>
            <Sectors_page />
            <Portal_page />
        </>
    )
}

export default Main_page;