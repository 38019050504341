import React from "react";
import { Container } from "react-bootstrap";
import Lottie from 'react-lottie';
import * as animationData from "./img/pattern.json";
import Phone from "./img/telephone.png";
import Sms from "./img/message.png";

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

const Feedback_page =() =>{
    return(
        <div className="sector">
            <div className="lottie_svg">
                <Lottie options={defaultOptions} />
            </div>
            <Container className="container_width_in">
                <span className="title_in">Қайта алоқа</span>
            </Container>
            <div className="feedback_b">
                <div>
                    <p>Ўзбекистон Республикаси Президенти Администрацияси<br></br> ҳузуридаги Иқтисодий тадқиқотлар ва ислоҳотлар маркази</p>
                    <ul className="feedback_list">
                        <li>
                            <img src={Phone} />
                            (78) 150-02-02
                        </li>
                        <li>
                            <img src={Sms} />
                            info@cerr.uz
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Feedback_page;