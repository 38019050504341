import React from "react";
import { Container } from "react-bootstrap";

import Icon_1 from "./img/icon_1.png";
import Icon_2 from "./img/icon_2.png";
import Icon_3 from "./img/icon_3.png";
import Icon_4 from "./img/icon_4.png";
import Icon_5 from "./img/icon_5.png";

const Usefull_links_white =() => {
    return(
        <div className="usefull_links_white">

            <Container className="container_width">

                <span className="usefull_title">Фойдали ресурслар</span>
                
                <ul className="usefull_list">
                    <li>
                        <a href="#"><img src={Icon_1} /></a>
                        <a href="#">Ўзбекистон Республикаси Президентининг расмий веб-сайти</a>
                    </li>
                    <li>
                        <a href="#"><img src={Icon_2} /></a>
                        <a href="#">Ўзбекистон Республикаси Ҳукумат портали</a>
                    </li>
                    <li>
                        <a href="#"><img src={Icon_3} /></a>
                        <a href="#">Норматив ҳуқуқий ҳужжатларининг муҳокамаси</a>
                    </li>
                    <li>
                        <a href="#"><img src={Icon_4} /></a>
                        <a href="#">Ягона интерактив давлат хизматлари портали</a>
                    </li>
                    <li>
                        <a href="#"><img src={Icon_5} /></a>
                        <a href="#">Ўзбекистон Республикасини ривожлантиришнинг бешта устувор йўналиши бўйича ҳаракатлар стратегияси</a>
                    </li>
                </ul>

            </Container>
        </div>
    )
}

export default Usefull_links_white;