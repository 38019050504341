import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sec_icon_1 from "./img/sec_icon_1.png";
import Sec_icon_2 from "./img/sec_icon_2.png";
import Sec_icon_3 from "./img/sec_icon_3.png";
import Sec_icon_5 from "./img/sec_icon_5.png";
import Sec_icon_6 from "./img/sec_icon_6.png";

import { Link } from "react-router-dom";
// import Lottie from 'react-lottie';
// import * as animationData from "./img/pattern.json";
import Slider from "./slider";

// const defaultOptions = {
//     loop: true,
//     autoplay: true, 
//     animationData: animationData,
//     rendererSettings: {
//       preserveAspectRatio: 'xMidYMid slice'
//     }
//   };

const Sectors_page =() => {
    return(
        <div className="sector">
            <Slider />
            <Container>
                {/* <div className="lottie_svg">
                    <Lottie options={defaultOptions} />
                </div> */}
                
                <Row className="similar_height">
                    <Col md="4" className="bg_white">
                        <div className="sector_box">
                            <span className="circle"><img src={Sec_icon_1} /></span>
                            Иқтисодий соҳалар
                        </div>
                        <ul className="sector_list">
                            <li><a href="#">Банк молия</a></li>
                            <li><Link to="/inner">Тадбиркорлик ва бизнес</Link></li>
                            <li><a href="#">Солиқ ва бюджет</a></li>
                            <li><Link to="/investment_page">Инвестиция</Link></li>
                        </ul>
                    </Col>
                    <Col md="4" className="bg_white">
                        <div className="sector_box">
                            <span className="circle"><img src={Sec_icon_2} /></span>
                            Ижтимоий соҳалар
                        </div>
                        <ul className="sector_list">
                            <li><Link to="/chart">Таълим</Link></li>
                            <li><Link to="/health_chart">Соғлиқни сақлаш</Link></li>
                            <li><Link to="/employment_page">Меҳнат ва бандлик муносабатлари</Link></li>
                            <li><a href="#">Ижтимоий ҳимоя</a></li>
                        </ul>
                    </Col>
                    <Col md="4" className="bg_white">
                        <div className="sector_box">
                            <span className="circle"><img src={Sec_icon_3} /></span>
                            Суд хуқуқ тизими
                        </div>
                        <ul className="sector_list">
                            <li><a href="#">Одил судлов ва қарорларнинг ижро этилиши</a></li>
                            <li><a href="#">Фуқароларнинг ҳуқуқ ва эркинликлари таъминланиши</a></li>
                            <li><a href="#">Ҳуқуқ тартибот ҳимояси ва хавфсизлик</a></li>
                            <li><a href="#">Ҳудудларда қонун ҳужжатларига риоя қилиниши</a></li>
                        </ul>
                    </Col>
                    <Col md="4" className="bg_white">
                        <div className="sector_box">
                            <span className="circle"><img src={Sec_icon_1} /></span>
                            Давлат бошқаруви
                        </div>
                        <ul className="sector_list">
                            <li><a href="#">Давлат бошқарувининг шаффофолиги ва очиқлиги</a></li>
                            <li><a href="#">Коррупцияга қарши курашиш</a></li>
                            <li><a href="#">Жамоатчилик назорати</a></li>
                            <li><a href="#">Давлат хизматини янада такомиллаштириш</a></li>
                        </ul>
                    </Col>
                    <Col md="4" className="bg_white">
                        <div className="sector_box">
                            <span className="circle"><img src={Sec_icon_5} /></span>
                            Ташқи сиёсий фаолият
                        </div>
                        <ul className="sector_list">
                            <li><a href="#">Ҳалқаро ҳамкорлик</a></li>
                            <li><a href="#">Ўзбекистон ва хорижий мамлакатлар</a></li>
                            <li><a href="#">Ўзбекистон ва халқаро ташкилотлар</a></li>
                            <li><a href="#">Ўзбекистон ва ҳалқаро хавфсизлик масалалари</a></li>
                        </ul>
                    </Col>
                    <Col md="4" className="bg_white">
                        <div className="sector_box">
                            <span className="circle"><img src={Sec_icon_6} /></span>
                            Тараққиёт стратегияси
                        </div>
                        <ul className="sector_list">
                            <li><a href="#">Lorem ipsum dolor sit</a></li>
                            <li><a href="#">amet, consetetur</a></li>
                            <li><a href="#">sadipscing elitr, sed diam</a></li>
                            <li><a href="#">nonumy eirmod tempor invidunt ut labore e</a></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Sectors_page;