import React from "react";
import { Container } from "react-bootstrap";
import { AiOutlineRight } from 'react-icons/ai';
import Minjust from "./img/org_name_img1.png";
import Cerr from "./img/org_name_img2.png";
import Uzinfocom from "./img/org_name_img3.png";
import Lottie from 'react-lottie';
import * as animationData from "./img/pattern.json";

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

const Organizations_page =() =>{
    return(
        <div className="sector">
            <div className="lottie_svg">
                <Lottie options={defaultOptions} />
            </div>
            <Container className="container_width_in">
                <span class="title_in">Масъул ташкилотлар</span>
                <div className="has_pad">
                    <a href="https://www.minjust.uz/ru/" className="org_name"><img src={Minjust} /><span>Ўзбекистон Республикаси Адлия вазирлиги <AiOutlineRight className="org_svg" /></span></a>
                    <a href="https://www.cer.uz/" className="org_name"><img src={Cerr} /><span>Иқтисодий тадқиқотлар ва ислоҳотлар маркази <AiOutlineRight className="org_svg" /></span></a>
                    <a href="https://uzinfocom.uz/uz/" className="org_name"><img src={Uzinfocom} /><span>“Ягона интегратор UZINFOCOM” МЧЖ <AiOutlineRight className="org_svg" /></span></a>
                </div>
            </Container>
        </div>
    )
}

export default Organizations_page;