import React from "react";
import { Container } from "react-bootstrap";
import Lottie from 'react-lottie';
import * as animationData from "./img/pattern.json";
import Stat_image from "./img/stat.png";

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

const Stat =() =>{
    return(
        <div className="sector">
            <div className="lottie_svg">
                <Lottie options={defaultOptions} />
            </div>
            <Container>
                <span className="title_in">Тадбиркорлик фаолиятини текшириш ва тафтиш қилиш</span>
                <span class="mini_text">2022 йил 1 январ ҳолатига</span>
                <img className="marg_b" src={Stat_image} />
                <span class="another_text">Ҳудудларда йўналишларни баҳолаш кўрсаткичлари <a href="https://rating.idm.uz">Иқтисодий тараққиёт ва камбағалликни қисқартириш вазирлиги</a> ҳамда <a href="https://b-indicator.uz" target="_blank">Адлия вазирлиги</a> томонидан НИЗОМ асосида ярим йиллик ва йил якуни бўйича амалга оширилади.</span>
            </Container>
        </div>
    )
}

export default Stat;