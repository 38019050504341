import React from "react";
// import Portal_img from "./img/portal_img.jpg";

const Portal_page =() => {
    return(
        <div className="portal_content">
            <div className="portal_text">
                <span>Портал ҳақида</span>
                Ўзбекистон Республикаси Президенти Администрациясининг 2022 йил 12 февралдаги 
                АФ-8-сонли фармойишига ижроси юзасидан “Ислоҳотларнинг амалга оширилишини баҳолаш ва мониторинг
                қилиш тизимини шакллантириш” тўғрисидаги фармойиши ижросини таъминлаш мақсадида мазкур портал ишлаб чиқилди
            </div>
        </div>
    )
}

export default Portal_page;