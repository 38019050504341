import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import News_img1 from "./img/news_img1.jpg";
import News_img2 from "./img/news_img2.jpg";
import News_img3 from "./img/news_img3.jpg";
import News_img4 from "./img/news_img4.jpg";
import News_img5 from "./img/news_img5.jpg";
import News_img6 from "./img/news_img6.jpg";
import News_img7 from "./img/news_img7.jpg";
import News_img8 from "./img/news_img8.jpg";
import News_img9 from "./img/news_img9.jpg";
import { FaEye } from 'react-icons/fa';
import { Link } from "react-router-dom";
import Pagination from 'react-bootstrap-4-pagination';
import Lottie from 'react-lottie';
import * as animationData from "./img/pattern.json";


const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

let paginationConfig = {
    totalPages: 4,
    currentPage: 1,
    showMax: 5,
    size: "lg",
    threeDots: true,
    prevNext: true,
    onClick: function (page) {
       console.log(page);
     }
  };

const News_page =() =>{
    return(
        <div className="sector">
            <div className="lottie_svg">
                <Lottie options={defaultOptions} />
            </div>
            <Container className="container_width_in">
                <span className="title_in">Янгиликлар</span>

                <Row>
                    <Col md="4" className="news_b">
                        <span className="news_img">
                            <img src={News_img4} />
                        </span>
                        <div className="news_t">
                            <span className="news_date">15 Июнь 2022</span>
                            <Link to="/news_in" className="news_title">Шавкат Мирзиёев раислигида аҳолини касб-ҳунарга ўқитиш юзасидан ўтказилган йиғилишда қандай масалалар кўтарилди?</Link>
                            <p>
                                Бугун, 15 июнь куни Президент Шавкат Мирзиёев раислигида аҳолини касб-ҳунарга ўқитиш ва мономарказлар фаолиятини такомиллаштириш
                                чора-тадбирлари юзасидан видеоселектор йиғилиши бўлиб ўтмоқда.
                            </p>
                            <span className="reed_stat"><FaEye /> 100</span>
                        </div>
                    </Col>
                    <Col md="4" className="news_b">
                        <span className="news_img">
                            <img src={News_img5} />
                        </span>
                        <div className="news_t">
                            <span className="news_date">03 Июнь 2022</span>
                            <a href="#" className="news_title">Ўзбекистон ва жаҳон иқтисодиёти</a>
                            <p>
                                "Иқтисодий шарҳ" журнали Халқаро ташкилотларнинг маърузалари шарҳини тайёрлади. Саноатни ривожлантириш ташкилотининг
                                (ЮНИДО) “2021 йилда жаҳон бўйича ишлаб чиқаришнинг ўсиши” мавзусидаги ҳисоботида 2021 йилда юқори ва ўрта технологияли
                                саноат тармоқларининг аксарияти ишлаб чиқариш ўсишининг пандемиягача бўлган даражасига етди (10% ёки ундан кўп).
                            </p>
                            <span className="reed_stat"><FaEye /> 89</span>
                        </div>
                    </Col>
                    <Col md="4" className="news_b">
                        <span className="news_img">
                            <img src={News_img6} />
                        </span>
                        <div className="news_t">
                            <span className="news_date">31 Май 2022</span>
                            <a href="#" className="news_title">Ўзбекистон электр ва газ бўйича табақалаштирилган тарифларга ўтади</a>
                            <p>
                                Ўзбекистон аҳолиси йил сайин ортиб бормоқда ва бугун биз иқтисодиёти шиддат билан ўсиб бораётган давлатлар
                                қаторига кирамиз. Демакки, электр энергияси ва газга бўлган талаб ортиб бораверади. Энергетика тармоғининг
                                янги тариф ислоҳотида ҳар бир Ўзбекистон фуқароси электр ва газ учун адолатли ҳақ тўлаш тизимига ўтадиv
                            </p>
                            <span className="reed_stat"><FaEye /> 110</span>
                        </div>
                    </Col>
                    <Col md="4" className="news_b">
                        <span className="news_img">
                            <img src={News_img7} />
                        </span>
                        <div className="news_t">
                            <span className="news_date">30 Май 2022</span>
                            <a href="#" className="news_title">АИ-80 маркали бензиннинг биржадаги нархи 2% га кўтарилди, пахта ёғи, суюлтирилган газ нархи пасайди</a>
                            <p>
                                23 – 29 май кунлари "Ўзбекистон республика товар-хом ашё биржаси" АЖнинг очиқ биржа савдолари орқали жами 2 255,1 млрд сўмлик товар ресурслари сотилди.
                            </p>
                            <span className="reed_stat"><FaEye /> 60</span>
                        </div>
                    </Col>
                    <Col md="4" className="news_b">
                        <span className="news_img">
                            <img src={News_img1} />
                        </span>
                        <div className="news_t">
                            <span className="news_date">26 Май 2022</span>
                            <a href="#" className="news_title">Ижтимоий ҳимояга муҳтож айрим фуқароларга бир марталик моддий ёрдам кўрсатилади</a>
                            <p>
                                Уйида вақтинча бўлмаганлиги ёки бошқа сабабларга кўра бир марталик моддий ёрдам пулларини ўз вақтида ололмаган
                                фуқаролар тўланмаган моддий ёрдам пулларини 2022 йил 1 сентябргача олиш ҳуқуқига эга
                            </p>
                            <span className="reed_stat"><FaEye /> 55</span>
                        </div>
                    </Col>
                    <Col md="4" className="news_b">
                        <span className="news_img">
                            <img src={News_img8} />
                        </span>
                        <div className="news_t">
                            <span className="news_date">23 Май 2022</span>
                            <a href="#" className="news_title">Спортчиларни профессионал тайёрлаш бўйича янги тизим жорий этилади: Шавкат Мирзиёев раислигида ўтказилаётган йиғилишдан асосийлари</a>
                            <p>
                                Президент раислигида спортни оммалаштириш, спорт таълимини яхшилаш ва иқтидорли спортчиларни тайёрлаш бўйича йиғилиш бошланди. Review.uz йиғилишдан асосийларини келтиради.
                            </p>
                            <span className="reed_stat"><FaEye /> 72</span>
                        </div>
                    </Col>
                    <Col md="4" className="news_b">
                        <span className="news_img">
                            <img src={News_img9} />
                        </span>
                        <div className="news_t">
                            <span className="news_date">19 Май 2022</span>
                            <a href="#" className="news_title">Оммавий қабулда мингдан зиёд инсоннниг муаммосига ечим топилди</a>
                            <p>
                                Уч кунлик мулоқотларда Бош прокуратура масъуллари кўриб чиққан 296 та ариза-шикоятнинг 130 таси, Ички ишлар вазирлиги тизимига оид 188 та мурожаатнинг 73 таси жойида ҳал қилинди.
                            </p>
                            <span className="reed_stat"><FaEye /> 12</span>
                        </div>
                    </Col>
                    <Col md="4" className="news_b">
                        <span className="news_img">
                            <img src={News_img2} />
                        </span>
                        <div className="news_t">
                            <span className="news_date">17 Май 2022</span>
                            <a href="#" className="news_title">
                                Товар-хом ашё биржасида савдоларни кўпайтириш,
                                маҳаллий корхоналар иштирокини кенгайтириш бўйича йиғилишдан асосийлари
                            </a>
                            <p>
                                Президент Шавкат Мирзиёев раислигида Республика товар-хом ашё биржаси фаолиятини такомиллаштириш чора
                                тадбирлари юзасидан видеоселектор йиғилиш бўлиб ўтмоқда. “Иқтисодий шарҳ” журнали йиғилишдан асосийларини келтирди.
                            </p>
                            <span className="reed_stat"><FaEye /> 33</span>
                        </div>
                    </Col>
                    <Col md="4" className="news_b">
                        <span className="news_img">
                            <img src={News_img3} />
                        </span>
                        <div className="news_t">
                            <span className="news_date">12 Май 2022</span>
                            <a href="#" className="news_title">Ғаллачиликда бозор механизмларини жорий қилиш давом эттирилади</a>
                            <p>
                                11 май куни Президент Шавкат Мирзиёев ҳузурида дон маҳсулотлари корхоналарида трансформация жараёнларининг
                                натижадорлигини ошириш чора-тадбирлари юзасидан йиғилиш бўлиб ўтди
                            </p>
                            <span className="reed_stat"><FaEye /> 45</span>
                        </div>
                    </Col>
                    <div className="pagination_b">
                        <Pagination {...paginationConfig} />
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default News_page;