import React from "react";
import { Container } from "react-bootstrap";
import Uzinfocom from "./img/uzinfocom.png";
import Cerr from "./img/cerr.png";

const Footer_page = () => {
    return(
        <div className="footer">
            <Container className="container_width">
                <ul className="footer_list">
                    <li><a href="https://www.minjust.uz/ru/">Ўзбекистон Республикаси <br></br>Адлия вазирлиги</a></li>
                    <li><a href="https://uzinfocom.uz/uz/"><img src={Uzinfocom} /></a></li>
                    <li><a href="https://www.cer.uz/"><img src={Cerr} /></a></li>
                </ul>
                <span className="attention_info">
                    Материаллардан фойдаланганда e-monitoringга ҳавола бўлиши шарт. <br></br>© Барча ҳуқуқлар ҳимояланган 2022
                </span>
            </Container>
        </div>
    )
}
export default Footer_page;