import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from 'react-lottie';
import * as animationData from "./img/pattern.json";
import Big_logo from "./img/big_logo.png";
import Tick from "./img/tick.png";

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

const About_portal =() => {
    return(
        <div className="sector">
            <div className="lottie_svg">
                <Lottie options={defaultOptions} />
            </div>
            <Container className="container_width_in">
                <Row className="flex_b">
                    <Col md="7">
                        <span className="title_in">Ислоҳотларнинг амалга оширилишини баҳолаш ва мониторинг қилиш тизими ҳақида</span>
                        <div className="about_text">
                            <p>
                                Ислоҳотларнинг амалга оширилишини баҳолаш ва мониторинг қилиш тизими Ўзбекистон Республикаси Президенти Aдминистрациясининг 2022-йил 12-февралдаги AФ-8-сон “Ўзбекистон Республикаси Президенти Aдминистрацияси ҳузуридаги Ижтимоий-иқтисодий ислоҳотлар акселератори фаолиятини самарали ташкил қилиш тўғрисида”ги фармойишига асосан Aдлия вазирлиги раҳбарлигида шакллантирилган ишчи гуруҳи томонидан ишлаб чиқилган.
                            </p>
                            <p>
                                Ушбу тизимнинг яратилишидан мақсад – бу мамлакатимизда амалга оширилаётган ислоҳотларни мониторинг қилиш, уларнинг натижаларини тўплаш, таҳлил қилиш, комплекс баҳолаш ва умумлаштириш ҳисобланади. Мазкур дастурий таъминот ўзида қуйидаги ахборот тизимларининг индикатор ва субиндикатор кўрсаткичларини жамлаган ҳолда ислоҳотларнинг барча соҳалар кесимидаги умумий кўрсаткичларини тақдим этади:
                            </p>
                        </div>
                    </Col>
                    <Col md="5">
                        <img src={Big_logo} />
                    </Col>
                </Row>
            </Container>
            <div className="simple_info">
                <ul className="info_list">
                    <li>
                        <img src={Tick} />
                        “Ҳуқуқ устуворлиги” ахборот тизими, (ҳудудларда ҳуқуқ устуворлигининг таъминланганлик ҳолатини аниқлаш);
                    </li>
                    <li>
                        <img src={Tick} />
                        “Хавфсиз йўл ахборот тизими, (автомобил йўлларида инсон хавфсизлигини ишончли таъминлаш ва ўлим ҳолатларини кескин камайтириш, туманлар (шаҳарлар)да йўл ҳаракатини самарали ташкил этиш ҳолатини аниқлаш);
                    </li>
                    <li>
                        <img src={Tick} />
                        “Business Indicator” ахборот тизими, (ҳудудларда тадбиркорликнинг ривожланганлик даражасини аниқлаш);
                    </li>
                    <li>
                        <img src={Tick} />
                        “E-Anticor.uz” ахборот тизими, (коррупцияга қарши курашиш ишларининг самарадорлигини аниқлаш);
                    </li>
                    <li>
                        <img src={Tick} />
                        “Rating” ахборот тизими, (статистик кўрсаткичлар ва сўровномалар натижалари асосида ҳудудларнинг ижтимоий-иқтисодий ривожланиш даражасини аниқлаш);
                    </li>
                    <li>
                        <img src={Tick} />
                        “Baho.gov.uz” ахборот тизими, (давлат органлари ва бошқа ташкилотлар томонидан жисмоний ва юридик шахсларга давлат хизматларини кўрсатиш соҳасидаги фаолияти самарадорлигини аниқлаш);
                    </li>
                    <li>
                        <img src={Tick} />
                        “Очиқ бюджет” ахборот тизими (бюджет маълумотларининг очиқлиги ва бюджет жараёнида фуқароларнинг фаол иштироки таъминланганлигини аниқлаш);
                    </li>
                    <li>
                        <img src={Tick} />
                        “Ўзбекистон халқаро рейтингларда” ягона электрон портали
                    </li>
                    <li>
                        <img src={Tick} />
                        Ochiqlik.uz ахборот тизими, (давлат органлари ва ташкилотларининг фаолияти очиқлиги таъминланиши, шунингдек, жамоатчилик назоратини самарали амалга оширилишини аниқлаш);
                    </li>
                    <li>
                        <img src={Tick} />
                        “Ўзбекистон халқаро рейтингларда” ягона электрон портали, (мамлакатимизнинг сиёсий-ҳуқуқий ва ижтимоий-иқтисодий соҳалардаги айрим ҳалқаро рейтинглар ўзида жамлаган).
                    </li>
                </ul>
            </div>
            
            <Container className="container_width_in">
                <div className="about_text">
                {/* <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script><lottie-player src="https://assets3.lottiefiles.com/packages/lf20_klycbrsi.json" background="transparent" speed="1" style="width: 300px; height: 300px;" loop controls autoplay></lottie-player> */}
                    <p>
                        Шунингдек, ислоҳотларни мониторинг қилиш, уларнинг натижаларини тўплаш, таҳлил қилиш, комплекс баҳолаш жараёнларига мамлакатимизда фаолият юритаётган ихтисослаштирилган илмий-тадқиқот марказларини, мустақил экспертларни жалб этиш ҳамда соҳалар кесимида уларнинг иштирокларини таъминлаш мазкур тизимнинг ўзига хос жиҳатидир.
                    </p>
                </div>
            </Container>
        </div>
    )
}

export default About_portal;