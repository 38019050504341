import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "./img/logo.png";


const Header_page = () => {
    return(
        <Row>
            <div className="header">
                <Col md="4">
                    <Link to="/" className="logo">
                        <img src={Logo} />
                        <span>
                            <i>e-monitoring.gov.uz</i>
                            Ислоҳотларнинг амалга оширилишини <br></br>баҳолаш ва мониторинг қилиш портали
                        </span>
                    </Link>
                </Col>
                <Col md="8">
                    <ul className="menu">
                        <li><Link to="/about_portal">Портал ҳақида</Link></li>
                        <li><Link to="/organizations">Масъул ташкилотлар</Link></li>
                        <li><Link to="/news">Янгиликлар</Link></li>
                        <li><Link to="/feedback">Қайта алоқа</Link></li>
                    </ul>
                    <div className="lang">
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Uz
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Ru</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">O`z</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
            </div>
        </Row>
    )
}

export default Header_page;