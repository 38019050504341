import "./e-monitoring/css/style.css";
import Footer_page from "./e-monitoring/footer";
import Header_page from './e-monitoring/header';
import Inner_page from "./e-monitoring/inner";
import Usefull_links_white from "./e-monitoring/usefull_links_white";
import Usefull_links from "./e-monitoring/usefull_links";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Twitter from "./e-monitoring/img/twitter.png";
import Facebook from "./e-monitoring/img/facebook.png";
import Instagram from "./e-monitoring/img/instagram.png";
import News_page from "./e-monitoring/news";
import News_in from "./e-monitoring/news_in";
import Organizations_page from "./e-monitoring/organizations";
import About_portal from "./e-monitoring/about_portal";
import Feedback_page from "./e-monitoring/feedback";
import Main_page from "./main_page";
import Stat from "./e-monitoring/stat";
import EducationChart from "./e-monitoring/chart";
import TerritoryChart from "./e-monitoring/territory_rating";
import Health_rating from "./e-monitoring/health_chart";
import Investment_chart from "./e-monitoring/investment_page";
import Employment_chart from "./e-monitoring/employment_page";

function App() {
  return (
    <BrowserRouter>
        <div className="App">
            <ul className="social_network">
              <li><a href="#"><img src={Twitter} /></a></li>
              <li><a href="#"><img src={Facebook} /></a></li>
              <li><a href="#"><img src={Instagram} /></a></li>
            </ul>
            <Header_page />
            <Switch>
                <Route exact path="/" component={Main_page} />
                <Route path="/inner" component={Inner_page} />
                <Route path="/news" component={News_page} />
                <Route path="/news_in" component={News_in} />
                <Route path="/organizations" component={Organizations_page} />
                <Route path="/about_portal" component={About_portal} />
                <Route path="/feedback" component={Feedback_page} />
                <Route path="/stat" component={Stat} />
                <Route path="/chart" component={EducationChart} />
                <Route path="/territory_rating" component={TerritoryChart} />
                <Route path="/health_chart" component={Health_rating} />
                <Route path="/investment_page" component={Investment_chart} />
                <Route path="/employment_page" component={Employment_chart} />
            </Switch>
            <Usefull_links_white />
            <Footer_page />
        </div>
    </BrowserRouter>
  );
}

export default App;
