import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from 'react-lottie';
import * as animationData from "./img/pattern.json";
import ReactHighcharts from "react-highcharts";

// import "./styles.css";

function EducationChart() {
  const config = {
    chart: {
      plotBackgroundColor: false,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Андижон вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: "258",
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 258
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
          top: "-45",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config2 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Бухоро вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: "247",
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 247
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config3 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Жиззах вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: "163",
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 163
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config4 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Қашқадарё вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 455,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 455
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config5 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Навоий вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 154,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 154
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config6 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Наманган вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 336,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 336
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config7 = {
    chart: {
      plotBackgroundColor: false,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Самарқанд вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 439,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 439
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config8 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Сурхондарё вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 274,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 274
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config9 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Сирдарё вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 103,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 103
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config10 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Тошкент вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 385,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 385
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config11 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Фарғона вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 517,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 517
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config12 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Хоразм вилояти",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 235,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 235
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config13 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Тошкент шаҳри",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 1004,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 1004
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const config14 = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      marginTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      spacingTop: 0
    },
    title: {
      text: "Қорақалпоғистон Республикаси",
      align: "center",
      verticalAlign: "middle",
      y: 110,
      style: {
        fontSize: 16,
        fontWeight: 600
      }
    },
    subtitle: {
      text: 240,
      align: "center",
      verticalAlign: "middle",
      y: 5,
      style: {
        fontSize: 24,
        fontWeight: 600
      }
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      },
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            legendItemClick: () => true
          }
        },
        showInLegend: false,
        borderWidth: 3,
        colors: ["#dd96e9", "#e9d4ed"]
      }
    },
    series: [
      {
        size: "100%",
        innerSize: "77%",
        data: [
          {
            name: "Listed",
            y: 240
          },
          {
            name: "Not Listed",
            y: 100
          }
        ]
      }
    ],
    credits: {
      enabled: false
    },
    legend: {
      itemStyle: {
        top: "-15",
        color: "#787878"
      },
      symbolRadius: 15
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
      <div className="sector">
          <div className="lottie_svg">
              <Lottie options={defaultOptions} />
          </div>
          <Container>
          <span class="title_in">Олий маълумотли аҳоли сони (минг киши)</span>
          <span className="mini_text">2022 йил 1 январ ҳолатига</span>
            <Row className="change_width_2">
                <Col md="2">
                    <ReactHighcharts config={config} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config2} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config3} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config4} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config5} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config6} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config7} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config8} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config9} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config10} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config11} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config12} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config13} />
                </Col>
                <Col md="2">
                    <ReactHighcharts config={config14} />
                </Col>
            </Row>
            <span className="another_text">Ҳудудларда йўналишларни баҳолаш кўрсаткичлари <a href="https://rating.idm.uz">Иқтисодий тараққиёт ва камбағалликни қисқартириш вазирлиги</a> ҳамда <a href="https://b-indicator.uz" target="_blank">Адлия вазирлиги</a> томонидан НИЗОМ асосида ярим йиллик ва йил якуни бўйича амалга оширилади.</span>
           </Container>
      </div>
  );
}
export default EducationChart;
